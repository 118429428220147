import { isObject } from 'lodash-es';

app.service('yupHelper', [
  '$filter',
  function ($filter) {
    this.translateErrorMessage = (message) => {
      if (!isObject(message)) return $filter('translate')(message);

      const { fieldKey, errorMessage, ...params } = message;
      const field_name = $filter('translate')(fieldKey);
      return $filter('translate')(errorMessage, {
        field_name,
        ...params,
      });
    };

    this.handleYupErrors = (errors) => {
      return errors.inner.reduce((currentError, nextError) => {
        const { path = '', message } = nextError;
        return { ...currentError, [path]: this.translateErrorMessage(message) };
      }, {});
    };

    this.yupValidationAt = (path, schema, payload) => {
      try {
        schema.validateSyncAt(path, payload);
        return { isValid: true, errors: { [path]: '' } };
      } catch (error) {
        return {
          isValid: true,
          errors: { [path]: this.translateErrorMessage(error.message) },
        };
      }
    };

    this.yupValidation = (schema, payload) => {
      try {
        schema.validateSync(payload, { abortEarly: false });
        return { isValid: true, errors: {} };
      } catch (error) {
        const errors = this.handleYupErrors(error);
        return { isValid: false, errors };
      }
    };
  },
]);
