var importAll = function (requireContext) {
  requireContext
    .keys()
    // Filter iife module required in
    // `app/javascript/packs/shop/createApp.js` to prevent call duplicated
    .filter(
      (key) =>
        ![
          'on-tabset-tab-clicked',
          'stripe.fpx.payment',
          'tracker.gtag',
          'tracker.line-lap',
          'tracker.shoplytics',
          'tracker.tiktok',
        ].some((module) => key.includes(module)),
    )
    .forEach(requireContext);
};

importAll(require.context('../../shop/modules', true));
