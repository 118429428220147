app.service('sfexpressService', [
  '$http',
  function ($http) {
    const SF_EXPRESS = ['sfexpress', 'hk_sfplus', 'hk_integrated_sf_pickup'];

    const SF_EXPRESS_TYPES = {
      sl_sf_smartlocker: 'sl_sf_smartlocker',
      eflocker: 'eflocker',
      circlek: 'circlek',
      sevenEleven: '711',
      store: 'store',
      centre: 'centre',
    };

    const isSfExpress = ({
      region_type,
      config_data,
      requires_customer_address,
    }) => {
      const isSfRegion = SF_EXPRESS.includes(region_type);
      const isSfExpressTypes = Object.values(SF_EXPRESS_TYPES).includes(
        config_data?.sfexpress_type,
      );
      return isSfRegion && isSfExpressTypes && !requires_customer_address;
    };

    const getStoreLocations = () => $http.get('/api/sfexpress/store_locations');

    const getCentreLocations = () =>
      $http.get('/api/sfexpress/centre_locations');

    const getEflockerLocations = () =>
      $http.get('/api/sfexpress/eflocker_locations');

    const getSevenElevenLocations = () =>
      $http.get('/api/sfexpress/seven_eleven_locations');

    const getCirclekLocations = () =>
      $http.get('/api/sfexpress/circlek_locations');

    const getLocationByTypePromise = (sfExpressType) => {
      switch (sfExpressType) {
        case SF_EXPRESS_TYPES.store:
          return getStoreLocations();
        case SF_EXPRESS_TYPES.centre:
          return getCentreLocations();
        case SF_EXPRESS_TYPES.eflocker:
          return getEflockerLocations();
        case SF_EXPRESS_TYPES.sevenEleven:
          return getSevenElevenLocations();
        case SF_EXPRESS_TYPES.circlek:
        default:
          return getCirclekLocations();
      }
    };

    return {
      SF_EXPRESS_TYPES,
      isSfExpress,
      getLocationByTypePromise,
    };
  },
]);
